/** @format */

import _ from 'lodash';

import Whitelabel from './lib/Whitelabel';

import support from './config/support';
import platformSupport from './config/platformSupport';
import defaultSwitches from './config/defaultSwitches';
import timings from './config/timings';
import parlays from './config/parlays';
import multirunners from './config/multirunners';
import pageSizes from './config/pageSizes';
import placers from './config/placers';
import settingsOptions from './config/settingsOptions';
import sports from './config/sports';
import offerGroups from './config/offerGroups';
import bookies from './config/bookies';
import betbar from './config/betbar';
import markets from './config/markets';
import priceHistory from './config/priceHistory';
import ptConfigSetting from './config/ptConfigSetting';

let config = {
  sessionCookiePrefix: 'mb1', //where does the session cookie go, this prefix helps us unvalidate bad/old cookies [WARNING] YOU WILL ALSO HAVE TO CHANGE THIS ON MOLLY3
  narrowBreakpointNav: 1279, //how small the window has to be before we use narrow nav menu
  narrowBreakpointContents: 599, //how small the window has to be before we use narrow contents

  apiFeedNoCompress: false, //disable api stream compression
  eventWatchBatchSize: 50, //how many events should we batch per watch

  //some options for Fuse.js used by the trade page search
  tradeSearchOptions: {
    shouldSort: true,
    caseSensitive: false,
    threshold: 0.2,
    minMatchCharLength: 3,
  },
  tradeMinSearch: 3,

  //what feature flags to request
  featureFlags: [
    'advancedTradeSettings', //can the user access experimental trade settings
    'netPositionGrids', //send net position grids flag (?)
    'lognoice', //is lognoice enabled
    'equivalentBetsOption', //can users toggle the equivalent bets option
    'translatedBetTypes', //use translated bet type descriptions as opposed to API ones
    'parlay', //is parlay visible
    'overround', //is the overround feature toggle visible
    'depositButton', //show the deposit button (the whitelabel must also have Whitelabel.externalUrls['deposit'])
    'flatten',
    'multirunner',
    'cupid', // can see cupid on the betslip
    'accasPage', // can see customer acc limits page
    'eventPage', //can see eventPage
    'cupidPricePreview', // for exchange show price being put up after costs
    'forex', // adriano edit custom currencies,
    'smartCredit', // is live balance enabled
    'horse',
    'sonic', // show beta button linking to sonic
    'casino',
  ],

  //orders with stake
  //these filters are used in a few places
  ordersWithStakeStatuses: ['open', 'pending', 'partial_void', 'done'],
  ordersOpenStatuses: ['open', 'pending'],

  //support and debug related features
  support: support.default,

  //the browsers and OS the platform supports
  platformSupport: platformSupport.default,

  //feature toggles
  defaultSwitches: defaultSwitches.default,
  //default page sizes
  pageSizes: pageSizes.default,
  //options for multi-choice settings
  settingsOptions: settingsOptions.default,

  //bet placer settings
  placers: placers.default,
  //parlays settings
  parlays: parlays.default,
  //multirunners settings
  multirunners: multirunners.default,

  //various intervals and timings for data streams, cooldowns, etc
  timings: timings.default,

  //list of markets
  //it's a really bad idea to mess with this since a lot of stuff is hardcoded to these names
  markets: markets.markets,
  marketNames: markets.marketNames,

  //sport
  sports: sports.default,
  //bet type configurations
  offerGroups: offerGroups.default,

  //some bookie definitions
  bookies: bookies.default,

  //betbar settings
  betbar: betbar.default,

  //price history graph setting
  priceHistory: {
    hiddenBookies: priceHistory.hiddenBookies,
    initialUnselectedBookies: priceHistory.initialUnselectedBookies,
  },

  //pt config setting
  ptConfigSetting: {
    hiddenSports: ptConfigSetting.hiddenSports,
  },
};

//utility that extracts sport names so we don't have to deep search for them every time
config.sportNames = {};
for (let sp in config.sports) {
  config.sportNames[sp] = config.sports[sp];
  for (let corr in config.sports[sp].correlated) {
    config.sportNames[corr] = config.sports[sp].correlated[corr];
  }
}

//we need some things to be ignored... like sports
config.snakeCamelMap = {};
for (let sportName in config.sportNames) {
  config.snakeCamelMap[sportName] = sportName;
}

//bet types map so we can more easily find bet types for translation purposes
config.betTypes = {};
for (let og in config.offerGroups) {
  config.snakeCamelMap[og] = og;
  for (let _bt in config.offerGroups[og].betTypes) {
    let bt = config.offerGroups[og].betTypes[_bt];
    config.betTypes[bt.type] = bt;
  }
}

_.merge(config, Whitelabel.config);
if (window && window.WL_CONFIG) {
  _.merge(config, window.WL_CONFIG);
  if (window.WL_CONFIG.config.supportedLanguages) {
    config.supportedLanguages = window.WL_CONFIG.config.supportedLanguages;
  }
}

export default config;
