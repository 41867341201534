/** @format */

exports.default = {
  '18bet': {
    //unique identifier
    color: '#ffa117', //color to be used in history graphs and betslip tints
    //isExchange: true, //is the bookie displayed as an exchange in the betslip
    name: { id: 'bookies.18bet', defaultMessage: '18bet' }, //translatable bookie name
    terms: 'https://www.18bet.com/en/pages/terms-and-conditions/', //where terms and conditions are located
    settlement: 'https://www.18bet.com/en/pages/betting-rules/', //where settlement is located
  },
  '3et': {
    color: '#4da1a9',
    name: { id: 'bookies.3et', defaultMessage: '3et' },
    terms: 'https://www.3et.com/account/helpandlearning/termsandconditions/termsandconditions/',
    settlement: 'https://www.3et.com/account/helpandlearning/rulesandregulations/sportsrules/',
  },
  '4casters': {
    color: '#343b86',
    name: { id: 'bookies.4casters', defaultMessage: '4casters' },
    terms: '',
    settlement: 'https://4casters.io/rules',
  },
  artemis: {
    color: '#32cd32',
    name: { id: 'bookies.artemis', defaultMessage: 'artemis' },
    terms: '',
    settlement: '/bookies/artemis.pdf',
  },
  bdaq: {
    color: '#853395',
    isExchange: true,
    name: { id: 'bookies.bdaq', defaultMessage: 'bdaq' },
    terms: 'https://betdaq.zendesk.com/hc/en-gb/categories/360002774560-Terms-and-Conditions',
    settlement:
      'https://betdaq.zendesk.com/hc/en-gb/articles/360016980760-Part-3-The-BETDAQ-Sportsbook-General-Rules',
  },
  bf: {
    color: '#ffb80c',
    isExchange: true,
    name: { id: 'bookies.bf', defaultMessage: 'bf' },
    terms: 'http://www.betfair.com/en/aboutUs/Terms.and.Conditions/',
    settlement: 'http://www.betfair.com/en/aboutUs/Rules.and.Regulations/',
  },
  'bf-es': {
    color: '#ffb80c',
    isExchange: true,
    name: { id: 'bookies.bf-es', defaultMessage: 'bf-es' },
    terms: 'https://www.betfair.es/es/conocenos/#terminos',
    settlement: 'https://www.betfair.es/es/conocenos/#reglas',
  },
  'bf-it': {
    color: '#ffb80c',
    isExchange: true,
    name: { id: 'bookies.bf-it', defaultMessage: 'bf-it' },
    terms: 'https://www.betfair.it/aboutUs/terminiecondizioni/notainformativa/#noselect',
    settlement: 'https://www.betfair.it/aboutUs/mercati/',
  },
  bill: {
    color: '#0B1433',
    name: { id: 'bookies.bill', defaultMessage: 'bill' },
    terms: 'https://www.williamhill.com/app/answers/detail/a_id/6719',
    settlement: 'https://www.williamhill.com/app/answers/detail/a_id/2063',
  },
  boom: {
    color: '#7cb91d',
    name: { id: 'bookies.boom', defaultMessage: 'boom' },
    terms: '',
    settlement: '',
  },
  bookeu: {
    color: '#000',
    name: { id: 'bookies.bookeu', defaultMessage: 'bookeu' },
    terms: '',
    settlement: '',
  },
  broker1: {
    color: '#444',
    name: { id: 'bookies.broker1', defaultMessage: 'broker1' },
    terms: '',
    settlement: '',
  },
  broker2: {
    color: '#555',
    name: { id: 'bookies.broker2', defaultMessage: 'broker2' },
    terms: '',
    settlement: '',
  },
  broker3: {
    color: '#666',
    name: { id: 'bookies.broker3', defaultMessage: 'broker3' },
    terms: '',
    settlement: '',
  },
  broker5: {
    color: '#777',
    name: { id: 'bookies.broker5', defaultMessage: 'broker5' },
    terms: '',
    settlement: '',
  },
  broker6: {
    color: '#888',
    name: { id: 'bookies.broker6', defaultMessage: 'broker6' },
    terms: '',
    settlement: '',
  },
  broker7: {
    color: '#999',
    name: { id: 'bookies.broker7', defaultMessage: 'broker7' },
    terms: '',
    settlement: '',
  },
  broker8: {
    color: '#AAA',
    name: { id: 'bookies.broker8', defaultMessage: 'broker8' },
    terms: '',
    settlement: '',
  },
  broker9: {
    color: '#BBB',
    name: { id: 'bookies.broker9', defaultMessage: 'broker9' },
    terms: '',
    settlement: '',
  },
  brokie: {
    color: '#ff00ff',
    name: { id: 'bookies.brokie', defaultMessage: 'brokie' },
    terms: '',
    settlement: '',
  },
  cmd: {
    color: '#226622',
    name: { id: 'bookies.cmd', defaultMessage: 'cmd' },
    terms: '/bookies/cmd.pdf',
    settlement: '/bookies/cmd.pdf',
  },
  accas: {
    color: '#FF0000',
    parlay: true,
    name: { id: 'bookies.accas', defaultMessage: 'accas' },
    terms: '',
    settlement: '',
  },
  dummy: {
    color: '#969696',
    name: { id: 'bookies.dummy', defaultMessage: 'dummy' },
    terms: '',
    settlement: '',
  },
  dummy2: {
    color: '#969696',
    name: { id: 'bookies.dummy2', defaultMessage: 'dummy2' },
    terms: '',
    settlement: '',
  },
  ga288: {
    color: '#6b8cce',
    name: { id: 'bookies.ga288', defaultMessage: 'ga288' },
    terms: 'http://ga288.com/member/static/index_info_en.jsp?page=3',
    settlement: '',
  },
  ibc: {
    color: '#007b9b',
    name: { id: 'bookies.ibc', defaultMessage: 'ibc' },
    terms: '/bookies/ibc.pdf',
    settlement: '/bookies/ibc.pdf',
  },
  isn: {
    color: '#085d8f',
    parlay: true,
    name: { id: 'bookies.isn', defaultMessage: 'isn' },
    terms: 'http://www.isn99.com/membersite-api/cms/rules/?locale=en_US#',
    settlement: 'http://www.isn99.com/membersite-api/cms/rules/?locale=en_US#',
  },
  iwin: {
    color: '#b38b12',
    name: { id: 'bookies.iwin', defaultMessage: 'iwin' },
    terms: '',
    settlement: '',
  },
  jabet: {
    color: '#8db900',
    name: { id: 'bookies.jabet', defaultMessage: 'jabet' },
    terms: 'https://www.jabet.com/General/Content-block.aspx#rules',
    settlement: '',
  },
  lsm: {
    color: '#f9f3a9',
    name: { id: 'bookies.lsm', defaultMessage: 'lsm' },
    terms: '',
    settlement: '',
  },
  mbook: {
    color: '#c51a1b',
    isExchange: true,
    name: { id: 'bookies.mbook', defaultMessage: 'mbook' },
    terms: 'https://www.matchbook.com/page/rules_and_regulations/market-rules',
    settlement: 'https://www.matchbook.com/page/rules_and_regulations/sports-rules/',
  },
  metric: {
    color: '#00FFFF',
    name: { id: 'bookies.metric', defaultMessage: 'metric' },
    terms: '/bookies/spm.pdf',
    settlement: '/bookies/spm2.pdf',
  },
  mmm: {
    color: '#ab1c5c',
    name: { id: 'bookies.mmm', defaultMessage: 'mmm' },
    terms: '/bookies/mmm.pdf',
    settlement: '/bookies/mmm.pdf',
  },
  molly: {
    color: '#838dff',
    isExchange: true,
    name: { id: 'bookies.molly', defaultMessage: 'molly' },
    terms: 'https://totusbet.com/termsandconditions.html',
    settlement: 'https://totusbet.com/termsandconditions.html',
  },
  penta88: {
    color: '#9B9B00',
    name: { id: 'bookies.penta88', defaultMessage: 'penta88' },
    terms: 'http://penta88.com/Betting-rules.html',
    settlement: '',
  },
  pin: {
    color: '#FF5500',
    parlay: true,
    name: { id: 'bookies.pin', defaultMessage: 'pin' },
    terms: 'https://www.pinnacle.com/en/termsandconditions',
    settlement: 'https://www.pinnacle.com/en/help/betting-rules',
  },
  pin88: {
    color: '#FF5500',
    name: { id: 'bookies.pin88', defaultMessage: 'pin88' },
    terms: 'https://www.ps3838.com/static/index.php/en-us/information/termsconditions',
    settlement: 'https://www.ps3838.com/static/index.php/en-us/help/bettingrules',
  },
  punter_io: {
    color: '#5696f3',
    name: { id: 'bookies.punter_io', defaultMessage: 'punter_io' },
    terms: '',
    settlement: '/bookies/punter_io.pdf',
  },
  redzone: {
    color: '#D21A1A',
    name: { id: 'bookies.redzone', defaultMessage: 'redzone' },
    terms: 'https://www.redzonesports.bet/en/terms_and_conditions/',
    settlement: 'https://www.redzonesports.bet/en/betting-rules/',
  },
  sbo: {
    color: '#5969c1',
    name: { id: 'bookies.sbo', defaultMessage: 'sbo' },
    terms: '/bookies/sbo.pdf',
    settlement: '/bookies/sbo.pdf',
  },
  sbo2: {
    color: '#5969c1',
    name: { id: 'bookies.sbo2', defaultMessage: 'sbo2' },
    terms: '/bookies/sbo.pdf',
    settlement: '/bookies/sbo.pdf',
  },
  sing2: {
    color: '#b26c05',
    parlay: true,
    name: { id: 'bookies.sing2', defaultMessage: 'sing2' },
    voidy: true,
    terms: '/bookies/sing2.pdf',
    settlement: '/bookies/sing2.pdf',
  },
  sjodds: {
    color: '#6691b3',
    name: { id: 'bookies.sjodds', defaultMessage: 'sjodds' },
    terms: '',
    settlement: '',
  },
  smk: {
    color: '#000',
    isExchange: true,
    name: { id: 'bookies.smk', defaultMessage: 'smk' },
    terms:
      'https://help.smarkets.com/hc/en-gb/articles/212183509-1-11-General-Smarkets-bettingrules',
    settlement: '',
  },
  spm: {
    color: '#00FFFF',
    name: { id: 'bookies.spm', defaultMessage: 'spm' },
    terms: '/bookies/spm.pdf',
    settlement: '/bookies/spm2.pdf',
  },
  sxbet: {
    color: '#2196f3',
    name: { id: 'bookies.sxbet', defaultMessage: 'sxbet' },
    terms: '',
    settlement: 'https://help.sx.bet/en/articles/3613418-general-betting-rules',
  },
  winft: {
    color: '#800080',
    name: { id: 'bookies.winft', defaultMessage: 'winft' },
    terms: '',
    settlement: '',
  },
  wlg: {
    color: '#ADFF2F',
    name: { id: 'bookies.wlg', defaultMessage: 'wlg' },
    terms: '',
    settlement: '',
  },
  wuming: {
    color: '#E6E6FA',
    name: { id: 'bookies.wuming', defaultMessage: 'wuming' },
    terms: '',
    settlement: '',
  },
  sharp: {
    color: '#144d0d',
    name: { id: 'bookies.sharp', defaultMessage: 'sharp' },
    terms: '',
    settlement: '/bookies/sharp.pdf',
  },
  vx: {
    color: '#f2a2e1',
    name: { id: 'bookies.vx', defaultMessage: 'vx' },
    terms: '',
    settlement: '/bookies/vx.pdf',
  },
  other: {
    color: '#000',
    name: { id: 'bookies.other', defaultMessage: 'other' },
    terms: '',
    settlement: '',
  },
};
